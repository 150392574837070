import Layout from '@components/layout'
import { useMediaQuery } from '@material-ui/core'
import CssBaseline from '@material-ui/core/CssBaseline'
import { ThemeProvider } from '@material-ui/styles'
import makeStyles from '@material-ui/styles/makeStyles'
import renderBlok from '@renderBlok'
import renderBloks from '@renderBloks'
import { Button, ContentContainer, PageContainer, ProfileCard } from '@system'
import ACC_THEME from '@themes'
import React, { useState } from 'react'
import SbEditable from 'storyblok-react'

const useStyles = makeStyles(() => ({
  championsIndexGrid: {
    display: 'grid',
    gap: '16px',
    gridTemplateColumns: '1fr 1fr',
    padding: '32px 0',
    [ACC_THEME.breakpoints.up('sm')]: {
      gridTemplateColumns: '1fr 1fr 1fr',
    },
    [ACC_THEME.breakpoints.up('md')]: {
      gridTemplateColumns: '1fr 1fr 1fr 1fr',
      padding: '56px 0',
    },
    [ACC_THEME.breakpoints.up('lg')]: {
      gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr',
    },
  },
  championsIndexMobileButton: {
    color: ACC_THEME.palette.common.black,
    display: 'block',
    margin: '8px auto 0',
    '& > span': {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      fontSize: '16px',
      fontWeight: ACC_THEME.typography.fontWeightRegular,
      gap: '4px',
      textDecoration: 'underline',
      textTransform: 'none',
      '& > span': {
        margin: '0',
        '& > svg': {
          transform: 'rotate(-90deg)',
        },
      },
    },
  },
}))

const ChampionsIndex = ({
  blok,
  championsDetailModulesStory,
  championsDetailStories,
}) => {
  const { body, hero, metaData } = blok
  const classes = useStyles()
  const isHandheldDevice = useMediaQuery(ACC_THEME.breakpoints.down('sm'))
  const [mobileChampionsVisible, setMobileChampionsVisible] = useState(6)

  const champions = isHandheldDevice
    ? championsDetailStories.slice(0, mobileChampionsVisible)
    : championsDetailStories

  const loadMoreChampions = () => {
    setMobileChampionsVisible(
      (previousMobileChampionsVisible) => previousMobileChampionsVisible + 6
    )
  }

  return (
    <Layout metaData={metaData}>
      <ThemeProvider theme={ACC_THEME}>
        <CssBaseline />
        <SbEditable content={blok}>
          <PageContainer>
            {hero?.length > 0 && renderBloks(hero)}
            <ContentContainer>
              {championsDetailStories?.length > 0 && (
                <>
                  <div className={classes.championsIndexGrid}>
                    {champions.map((champion) => (
                      <ProfileCard key={champion.id} {...champion} />
                    ))}
                  </div>
                  {isHandheldDevice &&
                    mobileChampionsVisible < championsDetailStories.length && (
                      <Button
                        className={classes.championsIndexMobileButton}
                        icon="ChevronLeft"
                        onClick={() => loadMoreChampions()}
                      >
                        More Champions
                      </Button>
                    )}
                </>
              )}
            </ContentContainer>
          </PageContainer>
          {body?.length > 0 && renderBloks(body)}
          {championsDetailModulesStory &&
            renderBlok(championsDetailModulesStory)}
        </SbEditable>
      </ThemeProvider>
    </Layout>
  )
}

export default ChampionsIndex
